<template>
<div>
  <section class="payment">
    <div class="payment__amount">
      <div class="payment__amount_info">
        <div class="payment__amount_info-text">
          <div class="payment__amount_title title">Сумма к оплате</div>
          <div class="payment__amount_price">₽ {{ order.amount }}</div>
        </div>
        <div id="app" class="timer"></div>
        <div class="base-timer">
          <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g class="base-timer__circle">
              <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>

              <path
                  id="base-timer-path-remaining"
                  :stroke-dasharray="dash_array"
                  class="base-timer__path-remaining"
                  :style="{'color': dash_color}"
                  d="
                      M 50, 50
                      m -45, 0
                      a 45,45 0 1,0 90,0
                      a 45,45 0 1,0 -90,0
                    "
              ></path>
            </g>
          </svg>
          <span id="base-timer-label" class="base-timer__label">{{rem_timer}}</span>
        </div>
      </div>
      <div class="payment__amount_card">
        <span id="copy_card">{{ order.requisities }}</span>
        <div class="separator"></div>
        <button class='payment__amount_btn' @click="CopyToClipboard()">
          <img src="assets/copy_btn.svg" alt="">
        </button>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import {v4} from "uuid";

export default {
  name: "PaymentComponent",
  methods:{


    async CopyToClipboard(){
      navigator.clipboard.writeText(this.order.requisities).then(() => { console.log(`Copied!`) })
          .catch((error) => { console.log(`Copy failed! ${error}`) })
    },

  },
  data(){
    return{
    }
  },
  props:{
    order: {
      type: Object
    },
    rem_timer: {
      type: String,
      default: "30:00",
      required: true
    },
    dash_array: {
      type: String,
      default: "283",
      required: true
    },
    dash_color: {
      type: String,
      default: "blue"
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>