<template>

  <div class="cancel__content">
    <img src="assets/cancel-svgrepo-com.svg" alt="Exclude">
    <div class="cancel_text">ВРЕМЯ ИСТЕКЛО</div>
    <button class='cancel__btn'>Вернуться на главную</button>
  </div>

</template>

<script>
export default {
  name: "TimeoutComponent"
}
</script>

<style scoped>

</style>