<template>
  <div class="complete__content">
    <img src="assets/Exclude.svg" alt="Exclude">
    <div class="complete_text">УСПЕШНАЯ ОПЛАТА</div>
    <button class='complete__btn'>Вернуться на главную</button>
  </div>
</template>

<script>
export default {
  name: "SuccessComponent"
}
</script>

<style scoped>

</style>