<template>
  <router-view/>
</template>

<style>

/*Обнуление*/
/*Обнуление*/

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  line-height: none;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*--------------------*/

body {
  height: 100%;
  font-size: 16px;
  background: #F5F5F5;
  color: #000000;
  scroll-behavior: smooth;
  font-family: 'Inter';
  overflow: hidden;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.inner {
  margin: 0 auto;
  max-width: 736px;
  z-index: 5;
}

.main {
  flex: 1 1 auto;
  min-width: 0;
}

.main__content {
  min-width: 0;
  margin: 0 10px;
}

/* ---------------- */

.payment-instructions {
  padding: 25px 35px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 36px;
  margin-top: 95px;
  margin-bottom: 25px;
  overflow: auto
}

.payment-instructions__title {
  font-size: 35px;
  margin-bottom: 17px;
}

.payment-instructions__body {
  list-style: none;
  font-size: 24px;
  line-height: 29px;
}

.payment-instructions__body li {
  display: flex;
  margin-bottom: 20px;
}

.payment-instructions__body li:last-child {
  margin-bottom: 0;
}

.num {
  border: 1.5px solid #000000;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  padding: 0 7px;
}

.num_2 {
  padding: 0 6px;
}

.title {
  font-weight: 600;
}

.thumbnail {
  font-weight: 500;
}

/* --------------------- */

.payment {
  display: flex;
  gap: 25px;
}

.payment__bank {
  padding: 61px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 36px;
}

.payment__amount {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 36px;
  padding: 25px 35px;
  width: 100%;
}

.payment__amount_info {
  display: flex;
  justify-content: space-between;
}

.payment__amount_title {
  font-size: 30px;
  margin-bottom: 6px;
}

.payment__amount_price {
  font-weight: 700;
  font-size: 30px;
  color: #29CA86;
  margin-bottom: 22px;
}

.payment__amount_card {
  font-weight: 600;
  border: 2px solid #000000;
  border-radius: 9px;
  padding: 12px 21px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 431px;
  margin-left: 90px;
}

.separator {
  height: 39.5px;
  opacity: 0.7;
  border-left: 2px solid #000000;
}

.timer {
  width: 55px;
  height: 55px;
}

.base-timer {
  position: relative;
  width: 55px;
  height: 55px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: #0187C8;
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
}
.server-not-found {
  margin: 0 auto;
}

/* ----------- */

.expectation {
  justify-content: center;
  gap: 20px;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  margin-left: 133px;
  padding: 50px;
}

.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 33px;
  height: 33px;
}
.spinner-first {
  width: 33px;
  height: 33px;
  z-index: 2;
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
}
.spinner-first .path-first {
  stroke: #000000;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

.spinner .path {
  stroke: #000000;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

/* ----------  */

.troubles {
  margin-top: 62px;
  font-weight: 600;
  font-size: 22px;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.troubles a {
  text-decoration: none;
  color: #FF5555;
  transition: .2s;
}

.troubles:hover a {
  text-decoration: underline;
}

/* ----------------- */

.complete {
  width: 100%;
  height: 100%;
  z-index: 9;
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: none;
}

.complete__content {
  width: 437px;
  background: #29CA86;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 41px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 43px 46px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.complete_text {
  font-size: 35px;
  line-height: 42px;
  color: #FFFFFF;
  max-width: 222px;
  text-align: center;
  margin: 24px 0 42px 0;
}

.complete__btn {
  background: none;
  border: 4px solid #FFFFFF;
  border-radius: 16px;
  padding: 16px 32px;
  transition: .3s;
  font-size: 23px;
  text-decoration: none;
  color: #FFFFFF;
}

.complete__btn:hover {
  border: 4px solid #29CA86;
  background: #FFFFFF;
  color: #29CA86;
}

.complete__btn:hover a {
  color: #29CA86;
}

.complete__btn a {
  font-size: 23px;
  text-decoration: none;
  color: #FFFFFF;
}

.cancel {
  width: 100%;
  height: 100%;
  z-index: 9;
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: none;
}
.cancel__content {
  width: 437px;
  background: #F00;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 41px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 43px 46px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.cancel_text {
  font-size: 35px;
  line-height: 42px;
  color: #FFFFFF;
  max-width: 222px;
  text-align: center;
  margin: 24px 0 42px 0;
}
.cancel__btn {
  background: none;
  border: 4px solid #FFFFFF;
  border-radius: 16px;
  padding: 16px 32px;
  transition: .3s;
}
.cancel__btn:hover {
  border: 4px solid #F00;
  background: #FFFFFF;
}
.cancel__btn:hover a {
  color: #F00;
}
.cancel__btn a {
  font-size: 23px;
  text-decoration: none;
  color: #FFFFFF;
}
.close {
  width: 100%;
  z-index: 2;
  position: absolute;
  top: 0px;
  padding: 30px;
  display: flex;
  justify-content: end;
}

.close_btn {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 19px;
  width: 59px;
  height: 59px;
  transition: .3s;
}

.close_btn:hover {
  background: #E2E2E2;
}


/* ADAPTIVE */

@media screen and (max-width: 768px) {
  body {
    overflow: auto;
  }
  .payment-instructions {
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .payment-instructions__title {
    font-size: 30px;
  }

  .payment-instructions__body {
    font-size: 19px;
    line-height: 25px;
  }

  .payment {
    gap: 20px;
  }

  .payment__bank {
    padding: auto;
  }

  .payment__amount_title {
    font-size: 30px;
  }

  .payment__amount_price {
    font-size: 30px;
  }

  .payment__amount_card {
    padding: 10px 15px;
    font-size: 30px;
  }

  .expectation {
    margin-top: 25px;
    font-size: 20px;
  }

  .spinner {
    width: 30px;
    height: 30px;
  }

  .troubles {
    margin-top: 45px;
    font-size: 20px;
  }

  .close {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  body {
    overflow: auto;
  }
  .payment-instructions {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .payment-instructions__title {
    font-size: 23px;
  }

  .payment-instructions__body {
    font-size: 15px;
    line-height: 22px;
  }

  .payment {
    gap: 16px;
  }

  .payment__bank {
    padding: 45px;
    display: none;
  }

  .payment__amount_title {
    font-size: 25px;
  }

  .payment__amount_price {
    font-size: 25px;
  }

  .payment__amount_card {
    padding: 7px 10px;
    font-size: 25px;
  }

  .expectation {
    margin-top: 20px;
    font-size: 20px;
  }

  .spinner {
    width: 30px;
    height: 30px;
  }

  .troubles {
    margin-top: 35px;
    font-size: 20px;
  }

  .complete__content {
    width: 310px;
  }

  .complete_text {
    font-size: 35px;
  }

  .complete__btn {
    padding: 12px 28px;
  }

  .complete__btn a {
    font-size: 23px;
    text-decoration: none;
    color: #FFFFFF;
  }
}

@media screen and (max-width: 380px) {
  body {
    overflow: auto;
  }
  .payment-instructions {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .payment__amount_card {
    padding: 7px 10px;
    font-size: 20px;
    width: 282px;
    margin: 0;
  }

  .expectation {
    margin-top: 15px;
    font-size: 15px;
  }

  .troubles {
    margin-top: 30px;
    font-size: 13px;
  }
}

@media screen and (max-width: 320px) {
  body {
    overflow: auto;
  }
  .payment-instructions {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .payment__amount_card {
    padding: 5px 8px;
    font-size: 15px;
  }

  .payment-instructions__title {
    font-size: 20px;
  }

  .payment-instructions__body {
    font-size: 12px;
    line-height: 20px;
  }

  .payment__amount_title {
    font-size: 20px;
  }

  .payment__amount_price {
    font-size: 20px;
  }

  .expectation {
    margin-top: 11px;
    font-size: 12px;
  }

  .troubles {
    margin-top: 20px;
    font-size: 13px;
  }
}

@media screen and (max-height: 720px) {
  body {
    overflow: auto;
  }
  .payment-instructions {
    margin-top: 45px;
    margin-bottom: 20px;
  }
}
</style>
