<template>
<div class="main inner">
  <div v-if="state_ok">

    <div v-if="!timeout && !completed">

      <HeaderComponent />
      <WaitingComponent v-if="loading"/>
      <PaymentComponent v-if="!loading"
                        :rem_timer="rem_timer"
                        :order="order"
                        :dash_array="dash_array"
                        :dash_color="dash_color"
          />
    </div>

    <div v-if="timeout">
      <TimeoutComponent/>
    </div>

    <div v-if="completed">
      <SuccessComponent/>
    </div>


  </div>

</div>
</template>

<script>
import HeaderComponent from "@/views/HeaderComponent";
import PaymentComponent from "@/views/PaymentComponent";
import WaitingComponent from "@/views/WaitingComponent";
import TimeoutComponent from "@/views/TimeoutComponent";
import SuccessComponent from "@/views/SuccessComponent";
import {v4 as v4, validate as validateUUID} from "uuid";



export default {
  name: "MainComponent",
  components: {SuccessComponent, WaitingComponent, HeaderComponent,PaymentComponent, TimeoutComponent },
  data(){
    return{
      state_ok: true,
      loading: true,
      completed: false,
      timeout: false,
      rem_timer: "30:00",
      order: {
        type: Object
      },
      interval: 10000,
      dash_array: "283",
      dash_color: "blue",
      payment_window: 0
    }
  },
  beforeMount() {
    if(typeof this.$route.query.uuid === 'undefined' || !validateUUID(this.$route.query.uuid))
      this.state_ok = false;
    else
      this.order.uuid = this.$route.query.uuid;

  },
  mounted() {
    this.ping_server()

      setInterval(async () => {

      if(this.timeout || this.completed)
        return;
      await this.check_order();
    },this.interval);

    setInterval(async () =>{

      await this.setTimer();

    },1000)
  },
  methods:{

    async check_order(){
      await this.axios.get("https://demo.pmntsys.com/api/gateway/refill/status/"+this.order.uuid).then((response) => {
          const rd = response.data;
          const order_data = rd.data;

          if(order_data.status === 'Finished'){
          this.completed = true;
          return;
          }

          if(order_data.requisities !== null && this.loading){
            this.loading = !this.loading;
            this.order = order_data;
            this.setTimer();
          }

        const payable_until = Math.floor(new Date(this.order.payable_until).getTime()/1000);
        const now = Math.floor(new Date().getTime()/1000);


          if(now > payable_until || this.order.status === 'Canceled' || this.order.status === 'Autocanceled'){
            this.timeout = true;
            return;
          }


      })

    },
    async setTimer(){

      const payable_until = Math.floor(new Date(this.order.payable_until).getTime()/1000);
      const now = Math.floor(new Date().getTime()/1000);

      const remain = payable_until - now;
      let min = Math.floor(remain/60);
      let sec = remain % 60;
      if(sec < 10){
        sec = '0'+sec;
      }
      if(min < 10){
        min = '0'+min;
        if(this.dash_color !== "orange" && min >= 5)
          this.dash_color = "orange";
        if(this.dash_color !== "red" && min <= 5)
          this.dash_color = "red";
      }
      // 14 / 30

      const rawTimeFraction = min / this.order.payment_window;
      const fraction = rawTimeFraction - (1 / this.order.payment_window) * (1 - rawTimeFraction);
      let cur_fraction = (fraction * 283).toFixed(0);

      this.dash_array = (cur_fraction.toString() + " 283").toString();
      console.log(this.dash_array);
      this.rem_timer = min+':'+sec;

    },

    async ping_server(){
      await this.axios.get("https://demo.pmntsys.com/api/gateway/ping").then((response) => {
        if(response.data.status !== 'PONG') {
            this.state_ok = false;
        }
      })
    }
  }
}
</script>




<style>

</style>