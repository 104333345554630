import { createRouter, createWebHistory } from 'vue-router'
import MainComponent from "@/views/MainComponent";


const routes = [
  {
    path: '/',
    name: 'main-component',
    component: MainComponent
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
