<template>
<div>
  <section class="payment-instructions">
    <div class="payment-instructions__title title">Указания к оплате</div>
    <ol class="payment-instructions__body">
      <li>
        <span class='num'>1</span>
        <span>
								Переведите <span class="thumbnail">на указанную ниже карту</span> в точночти
								<span class="thumbnail">указанную сумму</span> одним переводом.
							</span>
      </li>
      <li>
        <span class='num num_2'>2</span>
        <span>
								<span class="thumbnail">Обратите внимание,</span> номер карты меняется с каждым заказом!
							</span>
      </li>
      <section class='troubles'>
        <a href='#'>Проблема с оплатой</a>
      </section>
    </ol>
  </section>
</div>
</template>

<script>
export default {
  name: "HeaderComponent"
}
</script>

<style scoped>

</style>